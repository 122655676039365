import React from "react";

import { useArticle } from "features/articles/queries";
import Article from "features/articles/components/Article";
import Spinner from "components/Spinner";

export default function ArticlePreview({ leafname }) {
  const { data: article, status } = useArticle(leafname);

  if (status === "loading") {
    return <Spinner size={32} />;
  }

  return <Article article={article} />;
}
