import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Actions } from "@voxmarkets/vox-reducers";


export default function useMarketIndices() {
  const dispatch = useDispatch();
  const marketIndices = useSelector((state) =>
    state.marketIndices ? state.marketIndices.all : []
  );

  useEffect(() => {
    dispatch(Actions.marketIndices.fetchAll());
  }, [dispatch, marketIndices.length]);

  return marketIndices.filter( idx => !!idx.isPublished );
}
