import React from "react";
import styled from "styled-components";

import { atmedia } from "components/VoxTheme";
import { Spacing } from "components/Layout";
import Text from "components/Text";
import { Img, Link } from "components/Utils";
import { ShareMenu } from "components/ShareButton";

import { useSeries } from "features/series/queries";

const Header = styled.div`
  grid-area: header;
  background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    ${(props) =>
      props.background
        ? `url("${props.background}")`
        : "url(https://s3-eu-west-1.amazonaws.com/vox.assets.public/site-assets/default-header.jpg)"};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  overflow: hidden;
  width: 100%;
  padding: 1rem;
  ${atmedia.desktop`
  min-height: 240px;
  `};
  ${atmedia.tablet`
  min-height: 120px;
  `};
  ${atmedia.mobile`
  min-height: 80px;
  `};
`;

const SeriesImage = styled.div`
  display: none;
  ${atmedia.desktop`
    display: block;
    grid-area: image;
    width: 100%;
    img {
      display: block;
      width: 100%;
  }`}
`;

const SeriesSummary = styled.div`
  grid-area: summary;
`;

const InnerHeader = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-areas: "image" "summary";
  margin: 0 auto;
  max-width: 100%;
  ${atmedia.desktop`
    grid-template-columns: 200px 1fr; 
    grid-template-areas:  'image summary';
  `};
  ${atmedia.tablet`
    grid-template-columns: 200px 1fr; 
    grid-template-areas:  'summary summary';
  `};
`;

const SeriesLinks = styled(({ seriesAuthorId, className }) => (
  <Spacing top={1} className={className}>
    <Text size={0.8}>
      Vox Markets Video series: <Link href="/series/q-and-a/">Q&amp;A</Link>
      {" | "}
      <Link href="/series/industry-experts/">Industry Experts</Link>
      {" | "}
      <Link href="/series/business-lives-2018/">Business Lives</Link>
      {" | "}
      <Link href="/series/moving-averages-2018/">Moving Averages</Link>
      {" | "}
      <Link href="/series/elevator-pitches-2018/">Elevator Pitches</Link>
    </Text>
  </Spacing>
))`
  opacity: 0.8;
  ${Link}, ${Text} {
    color: #ddd;
  }
`;

export default function SeriesHeader({ seriesId, showLinks, titleAs = "h1" }) {
  const { data: series } = useSeries(seriesId);

  if (!series) {
    return null;
  }

  const {
    seriesLogoImageUrl,
    seriesImageUrl,
    seriesTitle,
    seriesSummary,
    seriesLeafname,
  } = series;

  return (
    <Header background={seriesImageUrl}>
      {seriesLogoImageUrl ? (
        <InnerHeader>
          <SeriesImage>
            <Link href={`/series/${seriesLeafname}/`}>
              <Img src={seriesLogoImageUrl} alt={seriesTitle} />
            </Link>
          </SeriesImage>
          <SeriesSummary>
            <Text as={titleAs} size={3} bold white shadow>
              {seriesTitle}
            </Text>
            <Text size={1} white dark>
              {seriesSummary}
            </Text>
            <Spacing top={0.5}>
              <Text as="span" light white shadow>
                Share:{" "}
              </Text>
              <ShareMenu
                text={seriesTitle}
                link={`https://www.voxmarkets.co.uk/series/${seriesLeafname}`}
                size={40}
              />
            </Spacing>
            {showLinks && <SeriesLinks />}
          </SeriesSummary>
        </InnerHeader>
      ) : (
        [
          <Text size={3} bold white shadow>
            {seriesTitle}
          </Text>,
          <Text size={1} white dark>
            {seriesSummary}
          </Text>,
        ]
      )}
    </Header>
  );
}
