import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { find, flatten, forEach, concat, groupBy, startsWith } from "lodash/fp";
import { v2API } from "@voxmarkets/api-sdk";

export const recentMediaAssetsKey = (mediaType) => [
  "mediaAssets",
  "recent",
  { mediaType },
];
export function recentMediaAssetsQuery(mediaType) {
  return {
    queryKey: recentMediaAssetsKey(mediaType),
    queryFn: () =>
      v2API.MediaAssets.getList({
        where: { mediaType: { like: `${mediaType}*` }, mediaIsClip: false },
        limit: 10,
      }),
    staleTime: 300000, // 5min
  };
}

export function recentMediaAssetsBySeries(mediaSeriesId, limit = 1){
  return {
    queryKey: recentMediaAssetsKey(mediaSeriesId),
    queryFn: () =>
      v2API.MediaAssets.getList({
        where: { mediaSeriesId, mediaIsClip: false },
        limit
      }),
    staleTime: 300000, // 5min
  };
}

export function useRecentMediaAssets(mediaType) {
  return useQuery(recentMediaAssetsQuery(mediaType));
}

export function useRecentSeriesMediaAssets(mediaSeriesId) {
  return useQuery(recentMediaAssetsBySeries(mediaSeriesId));
}

// ----

export function mediaAssetsQuery(objectId, config = {}) {
  return {
    queryKey: ["mediaAssets", "mentions", { objectId }],
    queryFn: () =>
      v2API.MediaAssets.getList({
        where: { mentions: `${objectId}` },
      }),
    staleTime: 600000, // 10min
    ...config,
  };
}

export function useMediaAssets(objectId) {
  const select = groupBy(({ mediaType }) => {
    if (
      startsWith("video", mediaType) ||
      mediaType === "application/bc-player" ||
      mediaType === "seeen"
    ) {
      return "videos";
    } else if (
      startsWith("audio", mediaType) ||
      mediaType === "embed/audioboom"
    ) {
      return "podcasts";
    } else if (mediaType === "application/pdf") {
      return "documents";
    }

    return "other";
  });

  return useQuery(mediaAssetsQuery(objectId, { select }));
}

//-----
export function seriesMediaAssetsQuery(seriesId, limit = 12) {
  return {
    queryKey: ["mediaAssets", "series", seriesId],
    queryFn: ({ pageParam = 0 }) =>
      v2API.MediaAssets.getList({
        where: { mediaSeriesId: `${seriesId}`, mediaIsClip: false },
        limit,
        skip: pageParam * limit,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === limit ? pages.length : undefined,
    staleTime: 300000, // 5min
  };
}

export function useSeriesMediaAssets(seriesId, limit = 12) {
  return useInfiniteQuery(seriesMediaAssetsQuery(seriesId, limit));
}

// ---
export const mediaAssetKey = (mediaAssetId) => ["media-asset", mediaAssetId];
export function mediaAssetQuery(mediaAssetId, config) {
  return {
    queryKey: mediaAssetKey(mediaAssetId),
    queryFn: () => v2API.MediaAssets.get(mediaAssetId),
    staleTime: 28800000, // 8h
    ...config,
  };
}

function getQueriesData(queries) {
  let data = [];
  forEach((query) => {
    const pages = query.state?.data?.pages;
    const list = query.state?.data;
    data = concat(data, pages ? flatten(pages) : list);
  }, queries);

  return data;
}

export function useMediaAsset(mediaAssetId) {
  const queryClient = useQueryClient();

  const initialData = () => {
    const queryCache = queryClient.getQueryCache();
    const queries = queryCache.findAll(["mediaAssets"]);
    return find({ id: mediaAssetId }, getQueriesData(queries));
  };

  return useQuery(mediaAssetQuery(mediaAssetId, { initialData }));
}
