import { v2API } from "@voxmarkets/api-sdk";

export function reportQuery(name, config = {}) {
  return {
    queryKey: ["report", name],
    queryFn: () => v2API.Reports.getReport(name),
    staleTime: 300000, // 5min
    ...config,
  };
}
