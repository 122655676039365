import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import PropTypes from "prop-types";
import RNSAnnouncement from "../components/RNS/Announcement";
import { getTitle } from "./../meta";
import AppActions from "./../redux/actions";
import Modal from "../components/Modal2";

import { track } from "../utils/analytics";
import { debounce } from "../utils";

const mapStateToProps = (state, props) => {
  return {
    meta: state.newsMetadata.byStoryId[props.storyId]
  };
};

const mapDispatchToProps = {
  getMetadata: id => Actions.newsMetadata.fetchForStories([id]),
  updateTitle: announcement =>
    AppActions.navigation.updateTitle(getTitle(announcement, "meta"))
};

class AnnouncementContainer_ extends PureComponent {
  static propTypes = {
    storyId: PropTypes.string.isRequired
  };

  state = { tracked: false }

  componentDidMount() {
    if (!this.props.meta || Object.keys(this.props.meta).length === 0) {
      this.props.getMetadata(this.props.storyId);
    }
    this.props.meta && this.onMetaUpdate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storyId !== this.props.storyId) {
      Object.keys(this.props.meta).length === 0 &&
      this.props.getMetadata(this.props.storyId);
    }
    if (!prevProps.meta || prevProps.meta.id !== this.props.meta.id) {
      this.onMetaUpdate();
    }
  }

  track = () => {
    if( !this.state.tracked && this.props.meta ){
      track("news", {
        action: "view",
        storyId: this.props.storyId,
        companyId: this.props.meta.publishingCompanyId
      });
      this.setState({ tracked: true })
    }
  }

  onMetaUpdate = () => {
    this.setState({ tracked : false }, this.track)
    this.props.updateTitle(this.props.meta);
  };

  render() {
    return this.props.meta ? (
      <RNSAnnouncement
        companyId={this.props.meta.publishingCompanyId}
        listingId={this.props.meta.publishingListingId}
        meta={this.props.meta}
      />
    ) : null;
  }
}

const AnnouncementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementContainer_);

//-------------------------------

class AnnouncementContainerModal extends PureComponent {
  state = { scrolled: false };
  _wrapper = React.createRef();
  track() {
    !this.state.scrolled &&
      track(
        "news",
        {
          action: "scroll",
          storyId: this.props.storyId
        },
        () => {
          this.setState({ scrolled: true });
        }
      );
  }
  onScroll = debounce(this.track, 200).bind(this);
  
  render() {
    return (
      <Modal
        width={65}
        onClose={this.props.onClose}
        iconColor="#fff"
        onScroll={this.onScroll}
        noPadding
      >
        <AnnouncementContainer storyId={this.props.storyId} />
      </Modal>
    );
  }
}

export default AnnouncementContainerModal;
