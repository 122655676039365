import { useQuery } from "react-query";
import { find, filter, includes } from "lodash/fp";

import { v2API } from "@voxmarkets/api-sdk";

export function seriesListQuery(config = {}) {
  return {
    queryKey: "series",
    queryFn: () => v2API.Content.getSeriesList(),
    staleTime: 28800000, // 8h
    ...config,
  };
}

export function useSeriesList() {
  return useQuery(seriesListQuery());
}

// ----

export function useSeries(seriesId) {
  return useQuery(
    seriesListQuery({
      select: find({ id: seriesId }),
    })
  );
}

// ----
export const selectByLeafname = (seriesLeafname) => find({ seriesLeafname });

export function useSeriesByLeafname(seriesLeafname) {
  return useQuery(
    seriesListQuery({
      select: selectByLeafname(seriesLeafname),
    })
  );
}

// ----

export const selectArchived = filter((s) =>
  includes(s.seriesLeafname, [
    "q-and-a",
    "spotlight",
    "industry-experts",
    "elevator-pitches-2018",
    "business-lives-2018",
  ])
);

export function useArchivedSeries() {
  return useQuery(
    seriesListQuery({
      select: selectArchived,
    })
  );
}

// ----

const selectByAuthor = seriesAuthorId => filter( s => s.seriesAuthorId === seriesAuthorId );

export function useSeriesByAuthorId(seriesAuthorId) {
  return useQuery(
    seriesListQuery({
      select: selectByAuthor(seriesAuthorId),
    })
  );
}
