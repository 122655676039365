import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Actions } from "@voxmarkets/vox-reducers";

import { selectIsAuthenticationCompleted } from "../redux/selectors";

export default function useMarketSectors() {
  const dispatch = useDispatch();

  const marketSectors = useSelector( state => state.staticData.sectors );
  const marketIndustries = useSelector( state => state.staticData.industries );
  
  useEffect(() => {
    dispatch(Actions.staticData.fetchAllSectors());
  }, [ dispatch, marketSectors.length]);

  useEffect(() => {
    dispatch(Actions.staticData.fetchAllIndustries());
  }, [ dispatch, marketIndustries.length]);

  return {
    supersectors : Object.values(marketSectors),
    sectors: Object.values(marketIndustries)
  };
}
