import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useIntersectionObserver from "hooks/useIntersectionObserver";

import { useAds } from "./queries";


const AdWrapper = styled.div`
  margin: auto;
  display: block;
  > ins, div { margin-left: auto !important; margin-right: auto !important; }
  div { display: block !important; }
  > [data-ad-status="unfilled"] { display: none !important; }
  
`;

const AdComponent = ({ slot }) => {

  const node = useRef(null);

  const { data: ads } = useAds();
  const selectedAd = ads.find( unit => (unit.vendor ==='adsense' && unit.slot === slot) );

  const [observe, isIntersecting] = useIntersectionObserver();

  useEffect(() => {
    if(isIntersecting && selectedAd && typeof window !== "undefined" ){
      window.setTimeout(() => {
        const s = document.createElement("script");
        s.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1460070887967178");
        s.setAttribute("crossorigin", "anonymous");
        s.setAttribute("async", "true");
        document.body.appendChild(s);
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      },100);
      return function(){
        window.adsbygoogle = [];
      }
    }
  }, [slot, isIntersecting]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      observe(node.current);
    }
  }, [observe, node]);

  if(!selectedAd){
    return null;
  }

  const { data } = selectedAd;
  
  return <AdWrapper ref={node}>
  {isIntersecting && (
    <ins key={slot} className="adsbygoogle"
         style={{ display:"block" }}
         data-ad-client="ca-pub-1460070887967178"
         data-ad-slot={ data['ad-slot'] }
         data-ad-format={data['ad-format'] }
         data-full-width-responsive={data['full-width-responsive'] } /> )}
  </AdWrapper>
  
}

export { AdComponent }
