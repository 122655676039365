import React from "react";
import { connect } from "react-redux";
import AppActions from "./../redux/actions";
import { loginOverlay } from "./../redux/selectors";
import LoginForm from "./../components/Login";

class LoginFormContiner extends React.Component {
  handleClose = e => {
    this.props.showLoginOverlay(false);
  };
  render() {
    return this.props.loginOverlay ? (
      <LoginForm onClose={this.handleClose} {...this.props}/>
    ) : null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    loginOverlay: props.forceOpen || loginOverlay(state)
  };
};

const mapDispatchToProps = {
  showLoginOverlay: state => AppActions.loginOverlay.show(false)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormContiner);
