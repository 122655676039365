import { useQuery, useQueryClient } from "react-query";

async function fetchAds() {
  return await fetch(`https://api.voxmarkets.co.uk/v4/cms/ads`).then(res => res.json());
}

function useAds(){
  return useQuery(
    "ads",
    () => fetchAds(),
    {
      staleTime: 300000, // 5min
    }
  );
}

export {
  fetchAds,
  useAds
}
