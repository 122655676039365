import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import Avatar from "../components/Avatar";

const mapStateToProps = (state, props) => ({
  companies: state.advCompanies.companies,
  users: state.users
});

const mapDispatchToProps = dispatch => ({
  getCompanyData(companyId) {
    dispatch(Actions.advCompanies.companies.getById(companyId));
  },
  getUserData(userId) {
    dispatch(Actions.users.fetchUserData(parseInt(userId)));
  }
});

class Header extends React.Component {
  componentDidMount() {
    const { companies, companyId, users, userId } = this.props;
    if (companyId && !companies[companyId]) {
      this.props.getCompanyData(companyId);
    } else if (userId && !users[userId]) {
      this.props.getUserData(userId);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { companies, companyId, users, userId } = this.props;
    return (
      nextProps.companyId !== companyId ||
      nextProps.userId !== userId ||
      JSON.stringify(nextProps.userData) !==
        JSON.stringify(this.props.userData) ||
      (!companies.hasOwnProperty(companyId) &&
        nextProps.companies.hasOwnProperty(companyId)) ||
      (!users.hasOwnProperty(userId) && nextProps.users.hasOwnProperty(userId))
    );
  }

  render() {
    const { companies, companyId, users, userId, ...props } = this.props;
    return (
      <Avatar
        companyData={companies[companyId]}
        userData={users[userId]}
        {...props}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
