import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { ShareMenu } from "../components/ShareButton";
import { getCanonicalUrl } from "./../meta";

import { useMediaAsset } from "features/media-assets/queries";
import { useArticleById } from "features/articles/queries"

const mapStateToProps = (state, { scope, text = "", link }) => {
  const { postId, storyId } = scope;
  if (text && link) {
    return { text, link };
  }
  if (storyId) {
    const meta = state.newsMetadata.byStoryId[storyId];
    if (meta) {
      text = meta.twitterShareText;
      link = getCanonicalUrl(meta, "meta");
    }
  } else if (postId) {
    text = text || "";
    link = getCanonicalUrl({ id: postId }, "post");
  }
  return { text, link };
};

const mapDispatchToProps = (dispatch, props) => ({
  getStory: storyId =>
    dispatch(Actions.newsMetadata.fetchForStories([storyId])),
  getPost: postId => dispatch(Actions.chronology.fetchSingle(postId))
});

function MediaShareMenu({id, size}) {
  const { data: mediaAsset } = useMediaAsset(id);

  if (!mediaAsset) {
    return null;
  }

  return (<ShareMenu
        link={getCanonicalUrl(mediaAsset, "media")} 
        text={mediaAsset.mediaTitle}
        size={size}
      />)
  
}

function ArticleShareMenu({id, size}) {
  const { data: article } = useArticleById(id);

  if (!article) {
    return null;
  }

  return (<ShareMenu
        link={getCanonicalUrl(article, "article")} 
        text={article.articleTitle}
        size={size}
      />)
  
}

class Wrapper extends React.Component {
  componentDidMount() {
    const { postId, storyId } = this.props.scope;
    if (!this.props.text) {
      if (storyId) {
        this.props.getStory(storyId);
      } else if (postId) {
        this.props.getPost(postId);
      }
    }
  }
  render() {
    const { articleId, mediaAssetId } = this.props.scope;
    
    if(mediaAssetId) {
      return <MediaShareMenu id={mediaAssetId} size={this.props.size} />
    }

    if(articleId) {
      return <ArticleShareMenu id={articleId} size={this.props.size} />
    }

    return (
      <ShareMenu
        link={this.props.link}
        text={this.props.text}
        size={this.props.size}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper);
