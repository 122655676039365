import { useQuery, useInfiniteQuery } from "react-query";
import { head } from "lodash/fp";
import { v2API } from "@voxmarkets/api-sdk";

// ---

function getColectionArticlesQuery(collectionId) {
  const limit = 10;

  return {
    queryKey: ["collections-articles", collectionId],
    queryFn: ({ pageParam = 0 }) =>
      v2API.Content.getCollectionArticlesList(collectionId, {
        skip: pageParam * limit,
        limit,
        order: "publishedAt DESC",
        fields: { articleBody: false },
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === limit ? pages.length : undefined,
    staleTime: 300000, // 5min
  };
}

function useColectionArticles(collectionId) {
  return useInfiniteQuery(getColectionArticlesQuery(collectionId));
}

// ---

function getColectionQuery(leafname) {
  return {
    queryKey: ["colection", leafname],
    queryFn: () =>
      v2API.Content.getArticleCollectionByLeafname(leafname).then(head),
    staleTime: 3600000, // 1h
  };
}

function useColection(leafname) {
  return useQuery(getColectionQuery(leafname));
}

export {
  useColectionArticles,
  useColection,
  getColectionQuery,
  getColectionArticlesQuery,
};
