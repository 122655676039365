import styled from "styled-components";
import { Link } from "react-router-dom";

import Badge from "components/Badge";
import { useSeries } from "features/series/queries";

const SeriesLink = styled(Link)`
  &:hover {
    div {
      color: rgba(19, 93, 128, 1);
    }
  }
`;

export default function SeriesBadge({ seriesId }) {
  const { data: series } = useSeries(seriesId);

  if (!series) {
    return null;
  }

  return (
    <SeriesLink to={`/series/${series.seriesLeafname}`}>
      <Badge info>See more in series</Badge>
    </SeriesLink>
  );
}
