import React from "react";

import { useMediaAsset } from "features/media-assets/queries";
import MediaItem from "features/media-assets/components/MediaItem";


export default function MediaAsset({ mediaAssetId, ...props }) {
  const { data: mediaAsset } = useMediaAsset(mediaAssetId);

  if (!mediaAsset) {
    return null
  }

  return <MediaItem mediaAsset={mediaAsset} {...props} />;
}
