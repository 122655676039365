import { useState, useRef, useEffect } from "react";

export default ({ root, rootMargin = "0px", threshold = 0 } = {}) => {
  const [entry, setEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }
    if (typeof window.IntersectionObserver !== "undefined") {
      observer.current = new window.IntersectionObserver(
        ([entry]) => {
          setEntry(entry);
          if (entry.isIntersecting) {
            observer.current.unobserve(node);
          }
        },
        {
          root,
          rootMargin,
          threshold
        }
      );

      if (node) {
        observer.current.observe(node);
      }
    } else {
      setEntry({ isIntersecting: true });
    }

    return () => observer.current.disconnect();
  }, [node, root, rootMargin, threshold]);

  return [setNode, entry.isIntersecting];
};
