import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import AttachmentPreview from "../containers/Attachment";
import { Grid } from "../components/Layout";
import DocumentLink from "../components/DocumentLink";

class Attachments extends React.Component {
  componentDidMount() {
    (!this.props.timeline || Object.keys(this.props.timeline).length === 0) &&
      this.props.getPreviews(this.props.postId);
  }
  render() {
    return this.props.timeline ? (
      <Grid>
        {this.props.timeline
          .filter(post => post.postType === "userattachment")
          .map(post => {
            if (
              post.hasOwnProperty("metadata") &&
              post.metadata["Content-Type"].includes("pdf")
            ) {
              return (
                <DocumentLink
                  type="pdf"
                  displayName={post.metadata.resourceName}
                  url={post.postContent}
                />
              );
            }
            return (
              <AttachmentPreview
                key={post.id}
                content={post.postContent}
                metadata={post.metadata}
              />
            );
          })}
      </Grid>
    ) : null;
  }
}

const mapDispatchToProps = dispatch => ({
  getPreviews: postId => dispatch(Actions.chronology.fetchAttachments(postId))
});

const mapStateToProps = (state, props) => {
  return { timeline: state.chronology.timelines[props.postId] };
};

const PreviewConnected = connect(mapStateToProps, mapDispatchToProps)(
  Attachments
);

export default PreviewConnected;
