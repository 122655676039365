import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { allWatchlists, allWatchlistsItems } from "../redux/selectors";
import AppActions from "./../redux/actions";
import CompanyFollowButton from "./../components/CompanyFollowButton";

const mapStateToProps = (state, props) => {
  return {
    loggedIn: state.authentication.loggedIn,
    watchlists: allWatchlists(state),
    watchlistItems: allWatchlistsItems(state)
  };
};

const mapDispatchToProps = {
  addlisting: (watchlistId, listingId) =>
    Actions.watchlists2.addItem(watchlistId, listingId),
  removeListing: (itemId, listingId) =>
    Actions.watchlists2.removeItem(itemId, listingId),
  showLoginOverlay: state => AppActions.loginOverlay.show(state),
  addWatchlist: () => AppActions.watchlist.showModal("create")
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyFollowButton);
