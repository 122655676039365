import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Actions } from "@voxmarkets/vox-reducers";

export default function useItemReactions(itemId, blockFetch) {
  const dispatch = useDispatch();
  const reactions = useSelector((state) => state.reactions.byTargetId[itemId]);

  useEffect(() => {
    if (!blockFetch && !reactions) {      
      dispatch(Actions.reactions.fetchForItem(itemId));
    }
  }, [blockFetch, dispatch, itemId, reactions]);

  return reactions;
}