import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import loadable from "@loadable/component";
import { useLocation } from "react-router-dom";
import Image from "components/Image";
import Text from "components/Text";
import { Spacing } from "components/Layout";
import { Link, Button } from "components/Utils";
import { Document } from "components/Icons";
import { Grid, Cell, Box } from "components/Layout";
import RatioBox from "components/RatioBox";
import Reactions from "components/Reactions";
import MediaPlayer from "components/MediaPlayer";

import { decodeHTML } from "utils";
import getImage from "utils/getImage";
import { track } from "utils/analytics";

import Badge from "features/series/components/SeriesBadge";

const Pdf =
  typeof window !== "undefined"
    ? loadable(() => import("components/PDF"))
    : () => null;


const Wrapper = styled.div`
  ${(props) => props.notPublished && `border: 1px solid red;`};
  margin-left: auto;
  margin-right: auto;
`;

const BRRIframe = styled.iframe`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Cover = styled(Image)`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CoverFrame = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #e0e7ec;
  overflow: hidden;
`;

const DocumentCard = styled(Box)`
  background-color: #e0e7ec;
`;

const extractThumbnail = ({ mediaMetadata, mediaUri }) => {
  if (mediaMetadata && mediaMetadata.hasOwnProperty("thumbnailUrl")) {
    const { thumbnailUrl } = mediaMetadata;
    if (thumbnailUrl.includes("theabcdn")) {
      return thumbnailUrl.replace(/\.png$/, ".jpg");
    }
    return thumbnailUrl;
  } else if (mediaUri && mediaUri.match(/youtu\.?be/)) {
    const url = mediaUri.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const id = undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    return `https://i.ytimg.com/vi/${id}/sddefault.jpg`;
  }
};

const getLinkWithContext = (location, id, context) => {
  if (context) {
    return `/media/${id}/?context=${context}`;
  } else if (location.pathname.indexOf("/media") === -1) {
    return `/media/${id}/?context=${location.pathname}`;
  }
  return `/media/${id}/`;
};

export default function MediaItem({
  mediaAsset,
  link,
  hideSeriesLink,
  onLinkClick,
  view = "compact",
  onOverlay,
  titleAs,
}) {
  const location = useLocation();

  if (!mediaAsset) {
    return null;
  }

  const {
    id,
    mediaType,
    mediaIsPublished,
    mediaUri,
    mediaMetadata,
    mediaTitle,
    publishedAt,
    mediaSeriesId,
    vendorsId,
  } = mediaAsset;

  const src = mediaMetadata && mediaMetadata.hasOwnProperty('youtubeUrl') ? mediaMetadata['youtubeUrl'] :  mediaUri;

  return (
    <Wrapper notPublished={!mediaIsPublished}>
      {(mediaType.toLowerCase().includes("seeen") ||
        mediaType.toLowerCase().startsWith("video") ||
        mediaType.toLowerCase().startsWith("audio") ||
        mediaType.toLowerCase().startsWith("embed")) && (
          <div>
            {!onOverlay ? (
              <Link href={getLinkWithContext(location, id, link)} overlay title={mediaTitle}>
                <RatioBox ratio="9/16">
                  <CoverFrame>
                    <Cover
                      src={extractThumbnail({ mediaMetadata, mediaUri })}
                      loading="lazy"
                      alt={mediaTitle}
                    />
                  </CoverFrame>
                </RatioBox>
              </Link>
              ) : (
              <MediaPlayer
                id={id}
                mediaType={mediaType}
                link={getLinkWithContext(location, id, link)}
                src={src}
                cover={mediaMetadata && getImage(mediaMetadata.thumbnailUrl)}
                jwPlatformID={mediaMetadata && mediaMetadata.jwplayerId}
                mediaMetadata={mediaMetadata}
                vendorsId={vendorsId}
                onPlay={onLinkClick}
              />
            )}
            <Spacing top={0.5}>
              <Link href={getLinkWithContext(location, id, link)} overlay>
                <Text lines={2} bold size={1} as={titleAs}>
                  {mediaTitle}
                </Text>
              </Link>

              <Spacing top={0.25}>
                <Grid middle>
                  <Cell fill>
                    <Link href={getLinkWithContext(location, id, link)} overlay>
                      <Text size={0.8} grey spacing={0.1}>
                        {format(new Date(publishedAt), "do MMM yyyy HH:mm")}
                      </Text>
                    </Link>
                  </Cell>
                  <Cell fit>
                    <Reactions itemId={id} compact />
                  </Cell>
                </Grid>
                {mediaSeriesId && !hideSeriesLink && (
                  <Spacing top={0.25}>
                    <Badge seriesId={mediaSeriesId} />
                  </Spacing>
                )}
              </Spacing>
            </Spacing>
          </div>
      )}
      {mediaType === "application/bc-player" && (
        <div>
          {onOverlay ? (
            <RatioBox ratio="9/16">
              <BRRIframe
                title="brr media"
                width="100%"
                height="100%"
                src={mediaUri}
                frameBorder={0}
                allowFullScreen
              />
            </RatioBox>
          ) : (
            <Link href={getLinkWithContext(location, id, link)} overlay>
              <RatioBox ratio="9/16">
                <Cover
                  src={mediaMetadata && getImage(mediaMetadata.thumbnailUrl)}
                  alt={mediaTitle}
                />
              </RatioBox>
            </Link>
          )}
          <Spacing top={0.5}>
            <Link href={getLinkWithContext(location, id, link)} overlay>
              <Text lines={2} bold size={1} as={titleAs}>
                {mediaTitle}
              </Text>
            </Link>
            <Spacing top={0.25}>
              <Grid middle>
                <Cell fill>
                  <Link href={getLinkWithContext(location, id, link)} overlay>
                    <Text size={0.8} grey spacing={0.1}>
                      {format(new Date(publishedAt), "do MMM yyyy HH:mm")}
                    </Text>
                  </Link>
                </Cell>
                <Cell fit>
                  <Reactions itemId={id} compact />
                </Cell>
              </Grid>
              {mediaSeriesId && !hideSeriesLink && (
                <Spacing top={0.25}>
                  <Badge seriesId={mediaSeriesId} />
                </Spacing>
              )}
            </Spacing>
          </Spacing>
        </div>
      )}
      {mediaType === "application/pdf" && (
        <>
          {view === "card" && (
            <Link
              href={getLinkWithContext(location, id)}
              target="_blank"
              onClick={onLinkClick}
            >
              <RatioBox ratio="9/16">
                {mediaMetadata && mediaMetadata.thumbnailUrl ? (
                  <CoverFrame>
                    <Cover
                      src={getImage(mediaMetadata.thumbnailUrl)}
                      alt="document"
                    />
                  </CoverFrame>
                ) : (
                  <DocumentCard middle center>
                    <Document size={80} />
                  </DocumentCard>
                )}
              </RatioBox>
            </Link>
          )}
          <Spacing vertical={1}>
            <Grid>
              <Cell fill>
                <Link
                  href={getLinkWithContext(location, id)}
                  onClick={onLinkClick}
                >
                  <Grid>
                    <Cell fit>
                      <Document />
                    </Cell>
                    <Cell fill>
                      <Text lines={2} size={1} as={titleAs}>
                        {decodeHTML(mediaTitle)}
                      </Text>
                      {publishedAt && (
                        <Text size={0.8} grey>
                          {format(new Date(publishedAt), "do MMM yyyy HH:mm")}
                        </Text>
                      )}
                    </Cell>
                  </Grid>
                </Link>
              </Cell>
              <Cell fit>
                <Link
                  href={mediaUri}
                  target="_blank"
                  onClick={() =>
                    track("news", { action: "download-pdf", mediaId: id })
                  }
                  style={{ textAlign: "right" }}
                >
                  <Button>Download PDF</Button>
                </Link>
              </Cell>
            </Grid>
          </Spacing>
          {onOverlay && <Pdf pdfUri={mediaUri} />}
        </>
      )}
    </Wrapper>
  );
}
