import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { isEqual } from "lodash";
import {
  selectEndedWatchedListings,
  selectDefaultListing,
  allWatchlistsItems
} from "./../redux/selectors";
import { track } from "../utils/analytics";

export class WatchlistUpdater extends React.Component {
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.endedListings, this.props.endedListings)) {
      this.props.endedListings
        .filter(item => {
          // to not migrate items that were added to a watchlist as ended
          return (
            Date.parse(item.createdAt) < Date.parse(item.listing.listingEndDate)
          );
        })
        .forEach(item => {
          // get "sibling" listings tied to the same issue
          this.props.fetchOtherListings([item.listing.issueId]).then(data => {
            // this will select the default listing using shared logic
            // (like when selecting a listing for a company),
            // using endDate and exchangeId
            const defaultListing = selectDefaultListing([
              item.listing,
              ...data.result
            ]);
            if (defaultListing.id !== item.listingId) {
              // check if the new listing is already watched;
              // if it is, then keep the old one next to it
              if (
                Object.values(this.props.allWatchlistsItems).findIndex(
                  watchlistItem => watchlistItem.listingId === defaultListing.id
                ) === -1
              ) {
                this.props.migrateListing(item, defaultListing.id);
              }
            }
          });
        });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    endedListings: selectEndedWatchedListings(state),
    allWatchlistsItems: allWatchlistsItems(state)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchCompanies: companyIds =>
    dispatch(Actions.advCompanies.companies.getByIds(companyIds)),
  fetchOtherListings: issueIds =>
    dispatch(Actions.advCompanies.listings.getByIssueIds(issueIds)),
  migrateListing: (oldItem, updatedListingId) => {
    dispatch(Actions.watchlists2.addItem(oldItem.watchlistId, updatedListingId))
      .then(() => {
        dispatch(
          Actions.watchlists2.removeItem(oldItem.id, oldItem.listingId)
        ).then(() => {
          track("watchlist-migrate", {
            error: null,
            oldListingId: oldItem.listingId,
            newListingId: updatedListingId,
            watchlistId: oldItem.watchlistId
          });
        });
      })
      .catch(err => {
        track("watchlist-migrate", {
          error: err,
          oldListingId: oldItem.listingId,
          newListingId: updatedListingId,
          watchlistId: oldItem.watchlistId
        });
      });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchlistUpdater);
