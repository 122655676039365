export const marketIndices = [
  { id: "5a10cc85ac5de600127fe8d1", leafname: "uk-100", indexName: "UK 100" },
  { id: "5a10cc85ac5de600127fe8c3", leafname: "uk-250", indexName: "UK 250" },
  { id: "5a10cc85ac5de600127fe8bc", leafname: "aim", indexName: "AIM" },
  { id: "5993224227bb7e00119e87c0", leafname: "all", indexName: "All" },
];

export const RNSmarketIndices = [
  { id: "5a10cc85ac5de600127fe8d1", leafname: "uk-100", indexName: "UK 100" },
  { id: "5a10cc85ac5de600127fe8c3", leafname: "uk-250", indexName: "UK 250" },
  { id: "5a10cc85ac5de600127fe8bc", leafname: "aim", indexName: "AIM" },
  /*{ id: "5a10cc85ac5de600127fe8bf", leafname:"main", indexName: "Main Market" }, this is UK All-share*/
  { id: "60b0913d86ebef2c82459695", leafname: "aqse", indexName: "AQSE" }
];
