import { useQuery, useQueryClient, useInfiniteQuery } from "react-query";
import { v2API } from "@voxmarkets/api-sdk";
import { head } from "lodash/fp";

async function fetchArticles({
  collectionId,
  where,
  skip,
  limit,
  order = "publishedAt DESC",
  fields = { articleBody: false },
}) {
  if(collectionId){
    return await v2API.Content.getCollectionArticlesList( collectionId, {
      where,
      skip,
      limit,
      order,
      fields,
    });
  }
  else {
    return await v2API.Content.getArticlesList({
      where,
      skip,
      limit,
      order,
      fields,
    });
  }
}

async function fetchCategories() {
  return await fetch(`https://api.voxmarkets.co.uk/v4/cms/categories`).then(res => res.json());
}

async function fetchAuthors() {
  return await fetch(`https://api.voxmarkets.co.uk/v2/cms/authors`).then(res => res.json());
}

// ---

function getArticlesInfiniteQuery() {
  const limit = 10;

  return {
    queryKey: "articles-recent",
    queryFn: ({ pageParam = 0 }) =>
      fetchArticles({ skip: pageParam * limit, limit }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === limit ? pages.length : undefined,
    staleTime: 300000, // 5min
  };
}

function getCategoryArticlesInfiniteQuery(category, categories) {
  const limit = 20;
  const filter = {};
  let key;

  if(Array.isArray(category) && categories) {
    key = category.join('_');
    const valid = category.map( cat => {
      return categories.find( c => c.label === cat);
    }).filter( c => !!c );
    
    if(valid.length > 0){
      filter.where = { articleCategory: { $in: valid.map( c => encodeURIComponent(c.value) )} }
    }
    else {
      filter.collectionId = "5afac5677708da0011824773";
      console.log(`invalid category ${category}`);
    }
  }
  else if(category && categories){
    key = category;
    const valid = categories.find( c => c.slug === category);

    if(valid){
      filter.where = { articleCategory: encodeURIComponent(valid.value) }
    }
    else {
      filter.collectionId = "5afac5677708da0011824773";
      console.log(`invalid category ${category}`);
    }
  }
  else {
    filter.collectionId = "5afac5677708da0011824773";
  }
  return {
    queryKey: `articles-${key}`,
    queryFn: ({ pageParam = 0 }) =>
      fetchArticles({ skip: pageParam * limit, limit, ...filter }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === limit ? pages.length : undefined,
    staleTime: 300000, // 5min
    enabled: !!categories
      };
}

function useCategories(){
  return useQuery(
    "article-categories",
    () => fetchCategories(),
    {
      staleTime: 300000, // 5min
    }
  );
}

function useAuthors(){
  return useQuery(
    "article-authors",
    () => fetchAuthors(),
    {
      staleTime: 300000, // 5min
    }
  );
}

function getCategoriesQuery(){
  return {
    queryKey: "article-categories",
    queryFn: () => fetchCategories(),
    staleTime: 300000, // 5min
  };
}

function getAuthorsQuery(){
  return {
    queryKey: "article-authors",
    queryFn: () => fetchAuthors(),
    staleTime: 300000, // 5min
  };
}

function useArticles() {
  return useInfiniteQuery(getArticlesInfiniteQuery());
}

function useCategoryArticles(category) {
  const { loading, data : categories } = useCategories();
  return useInfiniteQuery(getCategoryArticlesInfiniteQuery(category, categories));
}

// ----

function getUserArticlesQuery(articleAuthorId, filters = {}) {
  const limit = 20 || filters.limit;

  return {
    queryKey: ["user-articles", articleAuthorId],
    queryFn: ({ pageParam = 0 }) =>
      fetchArticles({
        where: { articleAuthorId, ...filters },
        skip: pageParam * limit,
        limit,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === limit ? pages.length : undefined,
    staleTime: 300000, // 5min
  };
}

function useUserArticles(articleAuthorId, filters = {}) {
  return useInfiniteQuery(getUserArticlesQuery(articleAuthorId, filters));
}

// ------



function useArticleById(articleId) {
  const filter = { where: { id: articleId } };

  return useQuery(
    ["article-details-id", articleId],
    () => fetchArticles(filter).then(head),
    {
      staleTime: 300000, // 5min
    }
  );
}

function useHomepageArticles(){
  const queryClient = useQueryClient();
  const query = getHomeArticlesQuery(queryClient);
  return useQuery(query);
}

const sortFeatured = (a, b) => {
  if(a.pinnedAt === b.pinnedAt){
    return new Date(a.publishedAt) > new Date(b.publishedAt) ? -1 : 1;
  }
  if(a.pinnedAt > b.pinnedAt){
    return 1;
  }
  return -1;
}

function getHomeArticlesQuery(queryClient){
  const past24h = new Date( new Date() - 24 * 60 * 60 * 1000 );
  return {
      queryKey: ["articles-home"],
      queryFn: async () => {
      const voxContent = await fetchArticles({ collectionId: "5afac5677708da0011824773", limit: 5 });
      const guestContent = await fetchArticles({ collectionId: "5eecfb59b82bd3aca5759019", limit: 9 });
      return [ ...voxContent.filter( art => new Date(art.publishedAt) > past24h ), ...guestContent ]
    },
    staleTime: 300000, // 5min
  }
}

// ---------

function getArticleQuery(articleLeafname, queryClient) {
  const filter = { where: { articleLeafname } };

  return {
    queryKey: ["article-details", articleLeafname],
    queryFn: () => fetchArticles(filter).then(head),
    staleTime: 300000, // 5min
    initialData: () => {
      return queryClient
        .getQueryData("articles-recent")
        ?.pages[0]?.find((d) => d.articleLeafname === articleLeafname);
    },
  };
}

function useArticle(articleLeafname) {
  const queryClient = useQueryClient();
  const query = getArticleQuery(articleLeafname, queryClient);
  return useQuery(query);
}

// ----------

function getArticleBodyQuery(articleId) {
  const filter = {
    where: { id: articleId },
    fields: { articleBody: true },
  };

  return {
    queryKey: ["article-body", articleId],
    queryFn: () => fetchArticles(filter).then(head),
    staleTime: 600000, // 10min
  };
}

function useArticleBody(articleId) {
  const query = getArticleBodyQuery(articleId);

  return useQuery(query);
}

export {
  fetchArticles,
  getArticlesInfiniteQuery,
  useArticles,
  useCategories,
  useAuthors,
  useCategoryArticles,
  useArticleById,
  useHomepageArticles,
  getHomeArticlesQuery,
  getUserArticlesQuery,
  getCategoriesQuery,
  getAuthorsQuery,
  getCategoryArticlesInfiniteQuery,
  useUserArticles,
  getArticleQuery,
  useArticle,
  useArticleBody,
  getArticleBodyQuery,
};
