import React from "react";
import ReactDOM from "react-dom";
import { loadableReady } from "@loadable/component";
import { Config } from "@voxmarkets/api-sdk";

import AppProviders from "./AppProviders";
import App from "./App";

Config.set("PUBLIC_HOST", process.env.REACT_APP_PUBLIC_HOST);
Config.set("API_HOST", process.env.REACT_APP_API_HOST);
Config.set("GRAPHQL_HOST", process.env.REACT_APP_GRAPHQL_HOST);

if (typeof window !== "undefined") {
  loadableReady(() => {
    ReactDOM.hydrate(
      <AppProviders>
        <App />
      </AppProviders>,
      document.getElementById("react-router")
    );
  });
}
