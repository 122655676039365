import React from "react";
import { connect } from "react-redux";
import Preview from "../components/URLPreview";
import AppActions from "./../redux/actions";
import { debounce } from "../utils/";

class Attachment extends React.Component {
  state = { url: null };
  componentDidUpdate(prevProps) {
    prevProps.content !== this.props.content &&
      this.parseInput(this.props.content);
  }

  componentDidMount() {
    this.props.content && this.findUrl(this.props.content);
  }

  parseInput = debounce(input => this.findUrl(input), 500);

  findUrl = string => {
    const chunks = string.split(/\s+/);
    const urlRegex = /^(https?:\/\/|www\.)[^.]+\.[^.]+/gi;
    const url = chunks.find(chunk => chunk.match(urlRegex));
    if (url && url !== this.state.url) {
      this.setState({ url }, () => {
        this.props.getPreview(this.state.url).then(r => {
          this.forceUpdate();
        });
      });
    } else if (!url) {
      this.setState({ url: null });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const key = encodeURI(this.state.url);
    if (
      nextState.url !== this.state.url ||
      nextProps.content !== this.props.content
    ) {
      return true;
    }
    if (
      !this.props.previews.hasOwnProperty(key) &&
      nextProps.previews.hasOwnProperty(key)
    ) {
      return true;
    }
    if (this.props.previews.hasOwnProperty(key)) {
      return (
        JSON.stringify(nextProps.previews[key]) !==
        JSON.stringify(this.props.previews[key])
      );
    }
    return false;
  }

  render() {
    const data =
      this.state.url &&
      this.props.previews.hasOwnProperty(encodeURI(this.state.url))
        ? this.props.previews[encodeURI(this.state.url)]
        : {};
    return <Preview preview={data} url={this.state.url} />;
  }
}

const mapDispatchToProps = dispatch => ({
  getPreview: url => dispatch(AppActions.previews.getPreview(url))
});

const PreviewConnected = connect(
  state => ({
    previews: state.previews
  }),
  mapDispatchToProps
)(Attachment);

export default PreviewConnected;




