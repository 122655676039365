import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import Text from "../components/Text";
import Badge from "../components/Badge";
import ListingBadgePopover from "../components/ListingBadgePopover";

const mapStateToProps = (state, { tickers }) => {
  const { listings, issues } = state.advCompanies;
  const listingIds = Object.keys(listings).filter(id =>
    tickers.includes(listings[id].listingShortTicker)
  );
  const mentions = listingIds.reduce((acc, id) => {
    if (!issues.hasOwnProperty(listings[id].issueId)) {
      return acc;
    }
    return [
      ...acc,
      {
        companyId: issues[listings[id].issueId].issueCompanyId,
        listingId: id,
        shortTicker: listings[id].listingShortTicker
      }
    ];
  }, []);
  return {
    mentions
  };
};

const mapDispatchToProps = (dispatch, { tickers }) => {
  return {
    getCompanyIds: tickers =>
      dispatch(
        Actions.advCompanies.listings.getList({
          where: {
            listingShortTicker: { inq: tickers }
          }
        })
      ).then(listings => {
        return dispatch(
          Actions.advCompanies.issues.getByIds(
            listings.result.map(listing => listing.issueId)
          )
        );
      })
  };
};

class Mentions extends React.Component {
  componentDidMount() {
    this.props.tickers.length > 0 &&
      this.props.getCompanyIds(this.props.tickers);
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.tickers) !==
        JSON.stringify(prevProps.tickers) &&
      this.props.tickers.length > 0
    ) {
      this.props.getCompanyIds(this.props.tickers);
    }
    if (
      JSON.stringify(this.props.mentions) !== JSON.stringify(prevProps.mentions)
    ) {
      this.props.setMentions &&
        this.props.setMentions({
          companies: this.props.mentions.map(mention => mention.companyId),
          users: []
        });
    }
  }
  shouldComponentUpdate(nextProps) {
    return (
      JSON.stringify(this.props.mentions) !==
        JSON.stringify(nextProps.mentions) ||
      (JSON.stringify(this.props.tickers) !==
        JSON.stringify(nextProps.tickers) &&
        nextProps.tickers.length > 0) ||
      this.props.content !== nextProps.content
    );
  }
  render() {
    const { mentions, tickers, content } = this.props;
    if (content && tickers && tickers.length > 0) {
      const chunks = content.split(/#(\S+)/g);
      const withBadges = chunks.map(chunk => {
        if (tickers.includes(chunk)) {
          const mention = mentions.find(
            mention => mention.shortTicker === chunk
          );
          if (mention) {
            return (
              <ListingBadgePopover
                atHover
                listingId={mention.listingId}
                companyId={mention.companyId}
                key={mention.companyId}
              >
                <Badge info>#{mention.shortTicker}</Badge>
              </ListingBadgePopover>
            );
          }
          return chunk;
        }
        return chunk;
      });
      return withBadges;
    }
    return mentions.length > 0 ? (
      <Text size={0.75}>
        tagged:
        {mentions.map(mention => (
          <ListingBadgePopover
            atHover
            listingId={mention.listingId}
            companyId={mention.companyId}
            key={mention.companyId}
          >
            <div>#{mention.shortTicker}</div>
          </ListingBadgePopover>
        ))}
      </Text>
    ) : null;
  }
}

const MentionsConnected = connect(mapStateToProps, mapDispatchToProps)(
  Mentions
);

export default MentionsConnected;
