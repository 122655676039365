import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import { isEqual } from "lodash";
import { Title, Header, Content } from "./../components/TimelinePost";

const Preview = props => (
  <div>
    <Header {...props} />
    <Title href={props.storyId ? `/rns/announcement/${props.storyId}` : null}>
      {props.postTitle}
    </Title>
    <Content {...props} />
  </div>
);
class RNSContainer extends Component {
  componentDidMount() {
    if (!this.props.metadata && this.props.storyId) {
      this.props.fetchMetadata(this.props.storyId);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.metadata, nextProps.metadata);
  }

  render() {
    if (this.props.metadata) {
      const {
        storyHeadline,
        summary,
        timestamp,
        publishingCompanyId
      } = this.props.metadata;
      const postData = {
        postTitle: storyHeadline,
        postContent: summary,
        source: publishingCompanyId,
        createdAt: timestamp,
        postType: "news",
        metadata: {
          storyId: this.props.storyId
        }
      };
      return <Preview {...postData} />;
    }
    return null;
  }
}

RNSContainer.propTypes = {
  storyId: PropTypes.string.isRequired
};

const mapStateToProps = (state, { metadata, storyId }) => {
  if (metadata) {
    return metadata;
  } else if (storyId) {
    metadata = state.newsMetadata.byStoryId[storyId];
  }
  return {
    metadata
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMetadata: id => dispatch(Actions.newsMetadata.fetchForStories([id]))
});

export default connect(mapStateToProps, mapDispatchToProps)(RNSContainer);
