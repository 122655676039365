import { legacyAPI } from "@voxmarkets/api-sdk";

/*
  THIS SHOULD REALLY BE PART OF vox-reducers/user_data/actions
  each fetch should be a separate action,
  getAllData something like the following (wrap all the actions in Promise.all)
  and it should rely on api-sdk methods, not fetch calls

  putting this here for easier refactoring
*/
/**
 * Fetches the meta if logged in .
 * If no meta found uses the defaultValue
 * If not logged in uses notLoggedInValue
 * @param  {string} metaKey - the meta key to fetch
 * @param  {} defaultValue - the default value to use if no meta found
 * @param  {} basic - the basic user info fetched
 * @param  {} notLoggedInValue - the value to use if not logged in.
 */
async function fetchVersion(metaKey, defaultValue, basic, notLoggedInValue) {
  const basicData = await basic;
  let metaValue;
  if (basicData.login == false) {
    metaValue = notLoggedInValue;
  } else {
    metaValue = await legacyAPI.Users.getMeta(metaKey).then(data => {
      return data && data.length > 0 ? data[0].meta_value : defaultValue;
    });
  }

  return {
    [metaKey]: metaValue
  };
}
export default data => {
  let userData = data;
  const basic = legacyAPI.Users.getCombinedData();

  return Promise.all([
    basic,
    fetchVersion("onboarding", "3", basic, "3"),
  ]).then(results => {
    results.forEach(data => {
      Object.assign(userData, data);
    });
    return userData;
  });
};
